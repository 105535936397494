import React, { useEffect, useState } from 'react';
import Cookies from "js-cookie";
import useFetch from "../api/HttpRequest";
import { User, LogOut } from 'lucide-react';

// import userImage from '../assets/image.png';


const AccountMenu = ({ user, setAccessToken, finalQueryCount, trailSubscriptionResponse, fetchTrailSubscriptionAdd }) => {
  const [isOpen, setIsOpen] = useState(false);
  const accessToken = Cookies.get('accessToken');
 
  const { fetchData: logoutFetch } = useFetch('/user/logout', {
    method: 'GET',
    silent: true,
  });

  const { data: manageSubscriptionResponse, fetchData: fetchManageSubscriptionAdd } = useFetch('/subscription/manage/subscription', {
    method: 'GET',
    accessToken,
    silent: true,
  });

  const handleLogout = async () => {
    await logoutFetch();
    setAccessToken(null);
    Cookies.remove('accessToken');
    window.location.reload();
  };

  const handleUpgradeToTrailClick = async () => {
    setIsOpen(false);
    await fetchTrailSubscriptionAdd();
  };

  useEffect(() => {
    if (trailSubscriptionResponse?.status) {
      window.location.href = trailSubscriptionResponse.data.payment_link;
    }
  }, [trailSubscriptionResponse]);

  useEffect(() => {
    if (manageSubscriptionResponse?.status) {
      window.location.href = manageSubscriptionResponse.data;
    }
  }, [manageSubscriptionResponse]);

  const renderMenuItems = () => {
    if (user?.subscriptionValidity.enterprise) {
      return (
        <div className="">
          {/* <div className="px-4 py-2 text-sm text-gray-400 border-b border-gray-600">{user?.email}</div>
          <div className="px-4 py-2 text-sm text-gray-400">Your Plan (Enterprise)</div> */}
          <div className='pb-2 border-b-[1px]  border-[#FFFFFF59]'>
          <button
            className="w-full  card_bg  !rounded-lg px-4 py-2 text-[12px] text-white hover:bg-gray-700 text-left font-bold"
            onClick={fetchManageSubscriptionAdd}
          >
            Manage Subscription
          </button>
          </div>
          <div className='pb-1 border-b-[1px] border-[#FFFFFF59]'>
          <button
            className="w-full px-4 py-2 text-[12px] text-white hover:bg-gray-700 text-left flex items-center"
            onClick={() => window.location.href='/profile'}
          >
            <User size={20} className="mr-2" /> View Profile
          </button>
          </div>
           <div className='pb-1'>
          <button
            className="w-full px-4 py-2 text-[12px] text-white hover:bg-gray-700 text-left flex items-center"
            onClick={handleLogout}
          >
            <LogOut size={20} className="mr-2" /> Log Out
          </button>
          </div>
        </div>
      );
    } else if (user?.subscriptionValidity.trail) {
      return (
        <div className="">
          
           <div className='pb-2 border-b-[1px]  border-[#FFFFFF59]'>
          <div className="px-4 py-2 text-[12px]  text-white  card_bg mx-3 !rounded-lg">Days remaining: {user?.daysRemaining}</div>
          </div>
          <div className='pb-1 border-b-[1px] border-[#FFFFFF59]'>
          <button
            className="w-full px-4 py-2 text-[12px] text-white hover:bg-gray-700 text-left flex items-center"
            onClick={() => window.location.href='/profile'}
          >
            <User size={20} className="mr-2" /> View Profile
          </button>
          </div>
          <div className='pb-1'>
          <button
            className="w-full px-4 py-2 text-[12px] text-white hover:bg-gray-700 text-left flex items-center"
            onClick={handleLogout}
          >
            <LogOut size={20} className="mr-2" /> Log Out
          </button>
          </div>
        </div>
      );
    } else {
      return (
        <div className="">
        
          <div className='pb-2 border-b-[1px]  border-[#FFFFFF59]'>
          <button
            className="w-full flex px-4 mt-1 py-2 text-sm font-bold !rounded-lg text-white bg-green-600 hover:bg-green-700"
            onClick={handleUpgradeToTrailClick}
          >
            Upgrade (14 days Trial)
          </button>
          </div>
       
          <div className='pb-1 border-b-[1px] border-[#FFFFFF59]'>
          <button
            className="w-full px-4 py-2 text-[12px] text-white hover:bg-gray-700 text-left flex items-center"
            onClick={() => window.location.href='/profile'}
          >
            <User size={20} className="mr-2" /> View Profile
          </button>
          </div>
          <div className='pb-1'>
          <button
            className="w-full px-4 py-2 text-[12px] text-white hover:bg-gray-700 text-left flex items-center"
            onClick={handleLogout}
          >
            <LogOut size={20} className="mr-2" /> Log Out
          </button>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="relative ">
      <div onClick={() => setIsOpen(!isOpen)} className='flex items-center justify-center gap-1 mr-5'>
      <button
        
        className="flex items-center justify-center   w-10 h-10 mt-3 rounded-full bg-gray-700 hover:bg-gray-600 focus:outline-none"
      >
         <User size={20} className="" /> 
      </button>
      <div className='mt-'>
      <span
          className={`ml-auto transform text-sm
            transition-transform duration-200 ${isOpen ? 'rotate-180' : ''}`}
        >
          ▼
        </span>
      </div>
      </div>
      {isOpen && (
        <>
          <div
            className="fixed inset-0 z-10"
            onClick={() => setIsOpen(false)}
          ></div>
          <div
            className={`absolute py-[6px] px-[12px] right-2  ${user?.subscriptionValidity.enterprise || user?.subscriptionValidity.trail ? "w-[212px]" : "w-[230px]"} border-[1px] border-[#39445B] min-h-[137px]  !rounded-lg bg-[#222D44] shadow-lg ring-1 ring-black ring-opacity-5 z-20`}
          >
            {renderMenuItems()}
          </div>
        </>
      )}
    </div>
  );
};

export default AccountMenu;
import { Typography } from "@mui/material";
import React from "react";
import AppleIcon from "@mui/icons-material/Apple";
import FacebookIcon from "@mui/icons-material/FacebookRounded";

const AuthButtons = ({ type, mode }) => {
  const renderIcon = () => {
    switch (type) {
      case "Google":
        return     <img src="https://www.svgrepo.com/show/475656/google-color.svg" alt="Google logo" className="w-6 h-6" />;
      case "Apple":
        return <AppleIcon fontSize="small" className="!text-white" />;
      case "Facebook":
        return <FacebookIcon fontSize="small" className="!text-white" />;
      default:
        return null;
    }
  };

  const handleAuthClick = () => {
    if (type === "Google") {
      window.location.href = 'https://stg-auth.evattai.com/auth/google';
    }
  };

  return (
      <div onClick={handleAuthClick} className="flex w-full h-full gap-2 items-center justify-center rounded-lg cursor-pointer">
       <div> {renderIcon()}</div> 
      <Typography className="!text-[16px] !font-semibold !text-[#344054">
        {mode === "signin" ? "Continue" : "Sign Up"} with {type}
      </Typography>
      </div>
  );
};

export default AuthButtons;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, {useContext, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import useFetch from "../api/HttpRequest";
import Logo from "../assets/image.png";
import routeConstant from "../utils/routeConstant";
import { Link, useNavigate, useParams } from "react-router-dom";
import Cookies from 'js-cookie';
import { UserContext } from "../providers/userProvider";

const ResetPassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const { id, tempKey } = useParams();
  const user = useContext(UserContext);
  const navigate = useNavigate();

  const { data: resetData, error: resetError, fetchData: resetFetch } = useFetch('/user/password/change', {
    method: 'POST',
    silent: false,
    successMessage: 'Password reset',
  });

 
  // Formik validation schema
  const validationSchema = Yup.object({
    password: Yup.string()
      .min(8, 'Password must be at least 8 characters')
      .matches(/[A-Z]/, 'Password must contain at least one capital letter')
      .matches(/[0-9]/, 'Password must contain at least one number')
      .matches(/[^A-Za-z0-9]/, 'Password must contain at least one symbol')
      .matches(/^\S*$/, 'Password must not contain spaces') // No spaces
      .required('Password is required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Confirm Password is required'),
  });

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const formData = {
          id: id,
          password: values.password,
          tempKey: tempKey,
        };
       
        await resetFetch({ data: formData });

      
      } catch (error) {
        console.log("An error occurred. Please try again.");
      } finally {
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [user, navigate]);

  useEffect(() => {
    if (resetData?.status === true) {
      const accessToken = resetData.data.accessToken;
      Cookies.set('accessToken', accessToken);
      
      // Force a page reload to reset the auth state
      window.location.href = routeConstant.dashboard;
    }
  }, [resetData]);

  // Helper functions to check password rules
  const isPasswordValid = {
    minLength: formik.values.password.length >= 8,
    hasCapitalLetter: /[A-Z]/.test(formik.values.password),
    hasNumber: /[0-9]/.test(formik.values.password),
    hasSymbol: /[^A-Za-z0-9]/.test(formik.values.password),
    passwordsMatch: formik.values.password === formik.values.confirmPassword,
    noSpaces: !/\s/.test(formik.values.password), // Ensure no spaces in the password
  };

  return (
    <div className="background2">
      <div className="min-h-screen flex flex-col items-center justify-center py-10 px-4">
        <div className="w-full max-w-md p-4 rounded-lg">
          {/* Logo */}
          <Link className='flex items-center mx-auto justify-center mb-5' to={routeConstant.dashboard}>
            <img src={Logo} alt="Evatt.AI" className="w-[102px] h-[20px]" />
          </Link>
          <h2 className="text-white text-center text-2xl mb-6">Reset Password</h2>

          <form onSubmit={formik.handleSubmit}>
            <div className="flex flex-col gap-6">
              {/* Password Input */}
              <div className="relative">
                <input
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={`w-full bg-[#FFFFFF0A] border-[1px] border-[#D0D5DD] rounded-lg py-3 px-4 text-white placeholder-gray-500 focus:outline-none focus:border-[#00A67E] ${
                    formik.touched.password && formik.errors.password ? 'border-yellow-500' : 'border-gray-600'
                  } rounded-lg focus:outline-none focus:border-blue-500`}
                  placeholder="New Password"
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
                >
                  {showPassword ? (
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />
                    </svg>
                  ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                    </svg>
                  )}
                </button>
              </div>

              {/* Confirm Password Input */}
              <div className="relative">
                <input
                  type={showConfirmPassword ? 'text' : 'password'}
                  name="confirmPassword"
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={`w-full bg-[#FFFFFF0A] border-[1px] border-[#D0D5DD] rounded-lg py-3 px-4 text-white placeholder-gray-500 focus:outline-none focus:border-[#00A67E] ${
                    formik.touched.confirmPassword && formik.errors.confirmPassword ? 'border-yellow-500' : 'border-gray-600'
                  } rounded-lg focus:outline-none focus:border-blue-500`}
                  placeholder="Confirm Password"
                />
                <button
                  type="button"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
                >
                  {showConfirmPassword ? (
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />
                    </svg>
                  ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                    </svg>
                  )}
                </button>
              </div>

              {/* Password Validation Rules */}
              <div className="grid grid-cols-1 md:grid-cols-2">
                <div className={`flex items-center ${isPasswordValid.minLength ? 'text-green-500' : 'text-gray-400'}`}>
                  <span className="mr-2">✔</span>
                  <span>At least 8 characters</span>
                </div>
                <div className={`flex items-center ${isPasswordValid.hasCapitalLetter ? 'text-green-500' : 'text-gray-400'}`}>
                  <span className="mr-2">✔</span>
                  <span>At least one capital letter</span>
                </div>
                <div className={`flex items-center ${isPasswordValid.hasNumber ? 'text-green-500' : 'text-gray-400'}`}>
                  <span className="mr-2">✔</span>
                  <span>At least one number</span>
                </div>
                <div className={`flex items-center ${isPasswordValid.hasSymbol ? 'text-green-500' : 'text-gray-400'}`}>
                  <span className="mr-2">✔</span>
                  <span>At least one symbol</span>
                </div>

                {/* Passwords match (only show if password or confirmPassword is not empty) */}
                {(formik.values.password || formik.values.confirmPassword) && (
                  <div className={`flex items-center ${isPasswordValid.passwordsMatch ? 'text-green-500' : 'text-gray-400'}`}>
                    <span className="mr-2">✔</span>
                    <span>Passwords match</span>
                  </div>
                )}

                {/* No spaces (only show if password is not empty) */}
                {formik.values.password && (
                  <div className={`flex items-center ${isPasswordValid.noSpaces ? 'text-green-500' : 'text-gray-400'}`}>
                    <span className="mr-2">✔</span>
                    <span>No spaces</span>
                  </div>
                )}
              </div>

              {/* Error Messages */}
              {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                <p className="text-yellow-500 text-sm">{formik.errors.confirmPassword}</p>
              )}
            </div>

            {/* Submit Button */}
            <button
              type="submit"
              className="w-full bg-green-600 text-white rounded-lg h-10 mt-8 hover:bg-green-700 transition-colors"
              disabled={loading || !formik.isValid}
            >
              {loading ? (
                <div className="flex justify-center items-center">
                  <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-white"></div>
                </div>
              ) : (
                'Reset Password'
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
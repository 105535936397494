/* eslint-disable no-unused-vars */
import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import routeConstant from "../utils/routeConstant";
import { useState } from "react";
import Logo from "../assets/image.png";
import useFetch from "../api/HttpRequest";

const ForgetPassword = () => {
  const [formData, setFormData] = useState({ email: "" });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const { data: resetData, error: resetError, fetchData: resetFetch } = useFetch('/user/forget/password', {
    method: 'POST',
    data: formData,
    silent: false,
    successMessage: 'Password reset link sent!',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async () => {
    setLoading(true);
    setError(null);
    try {
      await resetFetch();
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box className="flex flex-col items-center justify-center min-h-screen gap-5 py-10 background2">
       <Link to={routeConstant.dashboard}>
          <img src={Logo} alt="Evatt.AI" className="w-[102px] h-[20px]" />
        </Link>
      <Box className="flex flex-col gap-4 w-[30%]">
        <Box className="space-y-4">
          <Typography className="!text-[22px] !flex !justify-center !items-center !font-semibold !text-white">
            Forget Password
          </Typography>
          <Typography className="!text-[12px] justify-center !flex !items-center !font-bold !text-gray-400">
            Enter your email address to reset your password
          </Typography>
        </Box>
        <input
          placeholder="Email"
          type="email"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
          className="w-full  !border-[1px] !bg-[#FFFFFF0A] !border-[#D0D5DD] rounded-lg py-3 px-4 text-white placeholder-gray-500 focus:outline-none focus:border-[#00A67E]"
        />
        <Box className="flex justify-center">
          <Button
            onClick={handleSubmit}
            disabled={loading}
            className="!border !w-full !border-black !rounded-xl !py-2 !px-20 !text-[16px] !font-bold !flex !justify-center !items-center !bg-[#00A67E] !text-white !capitalize"
          >
            {loading ? "Sending..." : "Send Reset Link"}{" "}
          </Button>
        </Box>

        {/* Display error message */}
        {error && (
          <Typography className="!text-center !text-[12px] !font-bold !text-red-500">
            {error}
          </Typography>
        )}

        <Typography className="!text-center !text-[14px] !font-bold !text-gray-400 justify-center items-center flex gap-1">
          Remember your password?
          <Link to="/signin" className="text-[#00A67E] hover:underline">
                Sign in
              </Link>
        </Typography>
      </Box>
    </Box>
  );
};

export default ForgetPassword;

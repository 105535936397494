import {
  Box,
  Typography,
} from "@mui/material";
import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";

const DrawerChatsList = ({ chatList, onChatItemClick, onChatDelete }) => {

  return (
    <Box className="flex flex-col gap-[2px]">
      {chatList.map((item) => (
        <Box
          key={item.id}
          className="flex items-center mx-2 justify-between my-1 px-3 py-3 text-white bg-[#222D44] border-[#39445B] border-[0.8px] rounded-lg cursor-pointer text-nowrap"
          onClick={() => onChatItemClick(item.id)}
        >
          <Typography className="!text-[14px] !font-semibold truncate uppercase">
            {item.title}
          </Typography>
          <DeleteIcon
            fontSize="small"
            className="cursor-pointer ml-2 text-[#d6d6d6]"
            onClick={(e) => {
              e.stopPropagation();
              onChatDelete(item.id);
            }}
          />
        </Box>
      ))}
    </Box>
  );
};

export default DrawerChatsList;

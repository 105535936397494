/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import { Box, useMediaQuery } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import routeConstant from "../utils/routeConstant";
import CustomLink from "../components/CustomLink";
import { UserContext } from "../providers/userProvider";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../assets/image.png";
import UpgradePlanModal from "../components/UpgradePlanModal";
import Cookies from "js-cookie"; // Import js-cookie
import useFetch from "../api/HttpRequest";
import AccountMenu from "../components/AccountMenu";
import {  User } from 'lucide-react'
import { X, Camera, Eye, EyeOff } from 'lucide-react'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';



export default function ProfileSettings() {

  const user = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  const { subscriptionValidity } = user || {};

  const [isUpgradePlanModalOpen, setIsUpgradePlanModalOpen] = useState(false);
  const [isEnterprise, setIsEnterprise] = useState(false);
  const [isTrail, setIsTrail] = useState(false);
  const [finalQueryCount, setFinalQueryCount] = useState(null);
 
  const [selectedTab, setSelectedTab] = useState(() => {
    const savedTab = localStorage.getItem('selectedProfileTab');
    return savedTab || 'profile';
  });
  const [isPasswordValid, setIsPasswordValid] = useState({
    minLength: false,
    hasCapitalLetter: false,
    hasNumber: false,
    hasSymbol: false,
    noSpaces: false,
    passwordsMatch: false,
  });
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);

  // Add this function to handle password change
  const handlePasswordChange = (e, setFieldValue) => {
    const { name, value } = e.target;
    setFieldValue(name, value);

    if (name === 'newPassword') {
      const validation = validatePassword(value);
      setIsPasswordValid(validation);
    }
  };
  const [accessToken, setAccessToken] = useState(Cookies.get('accessToken'));

  

  

  const { data: manageSubscriptionResponse, loading: manageSubscriptionLoading, fetchData: fetchManageSubscriptionAdd } = useFetch('/subscription/manage/subscription', {
    method: 'GET',
    accessToken,
    silent: true,
  });

  const { data: trailSubscriptionResponse, loading: trailSubscriptionLoading, fetchData: fetchTrailSubscriptionAdd } = useFetch('/subscription/trail/add', {
    method: 'GET',
    accessToken,
    silent: true,
  });

  // Updated API hooks with correct useFetch implementation
  const { data: passwordChangeData, loading: passwordChangeLoading, fetchData: passwordChangeFetch } = useFetch('/user/change/password', {
    method: 'POST',
    accessToken,
    silent: false,
    successMessage: 'Password updated successfully'
  });

  const { data: basicInfoChangeData, loading: basicInfoChangeLoading, fetchData: basicInfoChangeFetch } = useFetch('/user/basic/info/change', {
    method: 'POST',
    accessToken,
    silent: false,
    successMessage: 'Profile information updated successfully'
  });
  const handleBasicInfoSubmit = async (values, { setSubmitting }) => {
    try {
      const fullName = `${values.firstName} ${values.lastName}`.trim();
      console.log( {
        fullName,
        mobile: values.mobile
      })
      await basicInfoChangeFetch({
        data: {
          fullName,
          mobile: values.mobile
        }
      });
     

    } catch (error) {
      console.error('Error updating profile:', error);
    }
    setSubmitting(false);
  };



  const handlePasswordSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      
      await passwordChangeFetch({
        data: {
          password: values.newPassword,
          oldPassword: values.oldPassword
        }
      });
      resetForm();
    } catch (error) {
      console.error('Error updating password:', error);
    }
    setSubmitting(false);
  };

 
  const getUserNames = () => {
    const fullName = user?.firstName || '';
    const names = fullName.split(' ');
    return {
      firstName: names[0] || '',
      lastName: names.slice(1).join(' ') || ''
    };
  };

  // Add this function to validate the password
  const validatePassword = (password) => {
    const minLength = password.length >= 8;
    const hasCapitalLetter = /[A-Z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSymbol = /[!@#$%^&*()_+{}[\]:;<>,.?~\\/-]/.test(password);
    const noSpaces = !/\s/.test(password);

    return {
      minLength,
      hasCapitalLetter,
      hasNumber,
      hasSymbol,
      noSpaces,
    };
  };

  // Form validation schemas
  const basicInfoSchema = Yup.object().shape({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    mobile: Yup.string()
      .matches(/^\+?[1-9]\d{1,14}$/, 'Please enter a valid phone number')
      .required('Mobile number is required'),
    email: Yup.string().email('Invalid email address')
  });

  const passwordSchema = Yup.object().shape({
    oldPassword: Yup.string()
      .required('Current password is required'),
    newPassword: Yup.string()
      .required('New password is required')
      .min(8, 'Password must be at least 8 characters long')
      .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
      .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
      .matches(/\d/, 'Password must contain at least one number')
      .matches(/[!@#$%^&*()_+{}[\]:;<>,.?~\\/-]/, 'Password must contain at least one special character'),
    confirmPassword: Yup.string()
      .required('Please confirm your new password')
      .oneOf([Yup.ref('newPassword')], 'Passwords must match')
  });

  useEffect(() => {
    localStorage.setItem('selectedProfileTab', selectedTab);
  }, [selectedTab]);

  useEffect(() => {
    if (user) {
      setFinalQueryCount(user.queryCount);

    }
  }, [user]);

  useEffect(() => {
    if (finalQueryCount >= 15 && !user?.subscriptionValidity.enterprise && !user.subscriptionValidity.trail) {
      setIsUpgradePlanModalOpen(true);
    }
  }, [finalQueryCount]);

  useEffect(() => {
    if (subscriptionValidity) {
      setIsEnterprise(subscriptionValidity.enterprise);
      setIsTrail(subscriptionValidity.trail);
    }
  }, [subscriptionValidity]);

  useEffect(() => {
    if (!accessToken) {
      navigate(routeConstant.signin);
    }
  }, [accessToken, navigate]);



  const isMobile = useMediaQuery('(max-width:767px)');

  const handlePlanButtonClick = () => {
    if (isEnterprise || isTrail) {
      fetchManageSubscriptionAdd();

    } else {
      setIsUpgradePlanModalOpen(true)
    }
  }
  useEffect(() => {
    if (manageSubscriptionResponse?.status) {
      window.location.href = manageSubscriptionResponse.data;
    }
  }, [manageSubscriptionResponse]);



  const handleActiveTabChange = (tab) => {
    setSelectedTab(tab);
  };


  useEffect(() => {
    // Simulate data fetching
    if (user) {
      setIsLoading(false); // Data is available, set loading to false
    } else {
      setIsLoading(true); // Data is not available, set loading to true
    }
  }, [user]);

  // Loading Skeleton Component
  const ProfileSettingsSkeleton = () => {
    return (
      <div className="min-h-screen background2 text-gray-100 p-4">
        {/* Header Skeleton */}
        <div className="h-[70px] hidden md:flex justify-between items-center w-full animate-pulse">
          <div className="h-10 w-32 bg-slate-700 rounded-lg"></div>
          <div className="flex gap-4">
            <div className="h-10 w-24 bg-slate-700 rounded-lg"></div>
            <div className="h-10 w-10 bg-slate-700 rounded-full"></div>
          </div>
        </div>

        {/* Main Content Skeleton */}
        <div className="flex flex-col lg:flex-row lg:max-w-[1200px] mt-12 mx-auto justify-center">
          {/* Sidebar Skeleton */}
          <div className="w-full lg:w-64 mb-4 lg:mb-0 space-y-2">
            <div className="h-10 bg-slate-700 rounded-lg"></div>
            <div className="h-10 bg-slate-700 rounded-lg"></div>
          </div>

          {/* Profile Section Skeleton */}
          <div className="flex-1 lg:ml-8">
            <div className="lg:max-w-[540px]">
              {/* Profile Picture Skeleton */}
              <div className="flex flex-col items-center mb-12">
                <div className="h-24 w-24 bg-slate-700 rounded-full"></div>
                <div className="h-4 w-32 bg-slate-700 rounded-lg mt-2"></div>
              </div>

              {/* Form Skeleton */}
              <div className="space-y-6">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div className="h-12 bg-slate-700 rounded-lg"></div>
                  <div className="h-12 bg-slate-700 rounded-lg"></div>
                </div>
                <div className="h-12 bg-slate-700 rounded-lg"></div>
                <div className="h-12 bg-slate-700 rounded-lg"></div>
                <div className="h-12 bg-slate-700 rounded-lg"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  // Show skeleton while loading
  if (isLoading) {
    return <ProfileSettingsSkeleton />;
  }



  return (
    <div className="min-h-screen background2 text-gray-100 md:p-4">
      
      <Box className={`h-[70px]  justify-between !border-[#39445B] !border-b-[1px] items-center w-full ${isMobile ? 'flex justify-between items-center ' : 'flex'}`}>
        <Box className="flex px- gap-4 md:mb-[1px] ">
          <button

            className="!flex !justify-start h-[64px]  !text-white !text-[14px] !font-extrabold !capitalize py-3 px- rounded-lg cursor-pointer hover:!bg-slate-700"
          >
            <img src={Logo} alt="Evatt.AI Logo" className="flex justify-start mt-2 md:mt-2 w-[110px] auto !ml-5" />
          </button>
        </Box>
        <div className="flex  gap-4">
          {accessToken || user ? (
            <>
              {/* Top Navigation */}
              <div className="flex items-center justify-end gap-4 p-4 ">

                <button onClick={handlePlanButtonClick} className="md:px-4 py-2 text-[10px] md:text-[12px] text-white hover:bg-gray-800 rounded-lg transition-colors">
                  {user?.subscriptionValidity.enterprise && "Manage Subscription"} {user?.subscriptionValidity.trail && `Days remaining: ${user?.daysRemaining}`}
                  {!user?.subscriptionValidity.enterprise && !user?.subscriptionValidity.trail && "Upgrade (14 days Trial)"}
                </button>

              
              </div>
              <AccountMenu
                user={user}
                setAccessToken={setAccessToken}
                finalQueryCount={finalQueryCount}
                trailSubscriptionResponse={trailSubscriptionResponse}
                fetchTrailSubscriptionAdd={fetchTrailSubscriptionAdd}
              />
            </>
          ) : (
            <Box className="flex gap-[8px] items-center">
              <CustomLink
                link={routeConstant.signin}
                bg="!bg-slate-800"
                border="!border-black"
              >
                Log In
              </CustomLink>
              <CustomLink
                link={routeConstant.signup}
                bg="!bg-transparent"
                border="!border-slate-800"
              >
                Sign Up
              </CustomLink>
            </Box>
          )}
        </div>
      </Box>
      <div className="flex p-4 flex-col lg:flex-row lg:max-w-[1200px] mt-12 mx-auto justify-center">
        {/* Sidebar */}
        <div className="w-full lg:w-64 mb-4 lg:mb-0 space-y-2">
          <button
            onClick={() => handleActiveTabChange('profile')}
            className={`w-full text-left px-4 py-2 rounded-lg ${selectedTab === 'profile'
              ? 'bg-slate-700 hover:bg-slate-600 border border-white'
              : 'bg-slate-800 hover:bg-slate-600'
              }`}
          >
            Profile
          </button>
          {/* <button 
            onClick={() => handleActiveTabChange('billing')} 
            className={`w-full text-left px-4 py-2 rounded-lg ${
              selectedTab === 'billing' 
                ? 'bg-slate-700 hover:bg-slate-600 border border-white' 
                : 'bg-slate-800 hover:bg-slate-600'
            }`}
          >
            Billing
          </button> */}
        </div>
        {selectedTab === 'profile' && (
          <>
            {/* Main Content */}
            <div className="flex-1  lg:ml-8">
              {/* Close Button */}
             
                <Link  to={routeConstant.dashboard}>
                <button className="float-right p-4 hover:bg-slate-700 rounded-full" >
                  <X className="w-6 h-6" />
                  </button>
                </Link>

             
              <div className="lg:max-w-[540px]">
                {/* Profile Section */}
                <div className="flex flex-col items-center mb-12">
                  <div className="relative">
                    <User size={120} className="w-24 h-24 rounded-full object-cover" />
                    <button className="absolute bottom-0 right-0 p-2 bg-slate-700 rounded-full hover:bg-slate-600">
                      <Camera className="w-5 h-5" />
                    </button>
                  </div>
                  <button className="mt-2 text-[12px] text-white hover:text-white">
                    Change profile picture
                  </button>
                </div>

                {/* Basic Info Form */}
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    firstName: getUserNames().firstName,
                    lastName: getUserNames().lastName,
                    mobile: user?.mobile ,
                    email: user?.email 
                  }}
                  validationSchema={basicInfoSchema}
                  onSubmit={handleBasicInfoSubmit}
                >
                  {({ isSubmitting, touched, errors }) => (
                    <Form className="space-y-6">
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                          <label className="block mb-2">First Name</label>
                          <Field
                            type="text"
                            name="firstName"
                            className={`w-full px-4 py-2 rounded-lg bg-[#FFFFFF0A] border-[1px] ${touched.firstName && errors.firstName ? 'border-yellow-500' : 'border-[#D0D5DD]'
                              } focus:outline-none focus:border-slate-500`}
                          />
                          <ErrorMessage name="firstName" component="p" className="text-yellow-500 text-sm mt-1" />
                        </div>

                        <div>
                          <label className="block mb-2">Last Name</label>
                          <Field
                            type="text"
                            name="lastName"
                            className={`w-full px-4 py-2 rounded-lg bg-[#FFFFFF0A] border-[1px] ${touched.lastName && errors.lastName ? 'border-yellow-500' : 'border-[#D0D5DD]'
                              } focus:outline-none focus:border-slate-500`}
                          />
                          <ErrorMessage name="lastName" component="p" className="text-yellow-500 text-sm mt-1" />
                        </div>
                      </div>

                      <div>
                        <label className="block mb-2">Mobile Number</label>
                        <Field
                          type="text"
                          name="mobile"
                          className={`w-full px-4 py-2 rounded-lg bg-[#FFFFFF0A] border-[1px] ${touched.mobile && errors.mobile ? 'border-yellow-500' : 'border-[#D0D5DD]'
                            } focus:outline-none focus:border-slate-500`}
                        />
                        <ErrorMessage name="mobile" component="p" className="text-yellow-500 text-sm mt-1" />
                      </div>

                      <div>
                        <label className="block mb-2">Email (Read Only)</label>
                        <Field
                          type="email"
                          name="email"
                          disabled
                          className="w-full px-4 py-2 rounded-lg bg-[#FFFFFF0A] border-[1px] border-[#D0D5DD] focus:outline-none focus:border-slate-500 opacity-70"
                        />
                      </div>

                      <div className="flex justify-end pt-4 border-t border-slate-700">
                        <button
                          type="submit"
                          disabled={isSubmitting || basicInfoChangeLoading}
                          className="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-8 rounded-lg disabled:opacity-50"
                        >
                          {basicInfoChangeLoading ? 'Saving...' : 'Save'}
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>

                {/* Password Change Form */}
                <div className="space-y-6 mt-12">
                  <Formik
                    initialValues={{
                      oldPassword: '',
                      newPassword: '',
                      confirmPassword: '',
                    }}
                    validationSchema={passwordSchema}
                    onSubmit={handlePasswordSubmit}
                  >
                    {({ isSubmitting, touched, errors, setFieldValue }) => (
                      <Form>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                          < div className="relative">
                            <label className="block mb-2">Current Password</label>
                            <Field
                              type={showOldPassword ? 'text' : 'password'}
                              name="oldPassword"
                              className={`w-full px-4 py-2 rounded-lg bg-[#FFFFFF0A] border-[1px] ${touched.oldPassword && errors.oldPassword ? 'border-yellow-500' : 'border-[#D0D5DD]'
                                } focus:outline-none focus:border-slate-500` }
                            />
                             <button
                                type="button"
                                onClick={() => setShowOldPassword(!showOldPassword)}
                                className="absolute inset-y-0 right-0 top-6 pr-3 flex items-center text-sm leading-5"
                              >
                                {showOldPassword ? (
                                  <Eye  className="h-5 w-5 text-white" />
                                ) : (
                                  <EyeOff className="h-5 w-5 text-white" />
                                )}
                              </button>
                            <ErrorMessage name="oldPassword" component="p" className="text-yellow-500 text-sm mt-1" />
                          </div>

                          < div className="relative">
                            <label className="block mb-2">New Password</label>
                            <Field
                              type={showNewPassword ? 'text' : 'password'}
                              name="newPassword"
                              onChange={(e) => handlePasswordChange(e, setFieldValue)}
                              className={`w-full px-4 py-2 rounded-lg bg-[#FFFFFF0A] border-[1px] ${touched.newPassword && errors.newPassword ? 'border-yellow-500' : 'border-[#D0D5DD]'
                                } focus:outline-none focus:border-slate-500`}
                            />
                             <button
                                type="button"
                                onClick={() => setShowNewPassword(!showNewPassword)}
                                className="absolute inset-y-0 right-0 top-6 pr-3 flex items-center text-sm leading-5"
                              >
                                {showNewPassword ? (
                                  <Eye  className="h-5 w-5 text-white" />
                                ) : (
                                  <EyeOff className="h-5 w-5 text-white" />
                                )}
                              </button>
                            <ErrorMessage name="newPassword" component="p" className="text-yellow-500 text-sm mt-1" />
                          </div>

                          < div className="relative">
                            <label className="block mb-2">Confirm New Password</label>
                            <Field
                              type={showConfirmPassword ? 'text' : 'password'}
                              name="confirmPassword"
                              className={`w-full px-4 py-2 rounded-lg bg-[#FFFFFF0A] border-[1px] ${touched.confirmPassword && errors.confirmPassword ? 'border-yellow-500' : 'border-[#D0D5DD]'
                                } focus:outline-none focus:border-slate-500`}
                            />
                             <button
                                type="button"
                                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                className="absolute inset-y-0 right-0 top-6 pr-3 flex items-center text-sm leading-5"
                              >
                                {showConfirmPassword ? (
                                  <Eye  className="h-5 w-5 text-white" />
                                ) : (
                                  <EyeOff className="h-5 w-5 text-white" />
                                )}
                              </button>
                            <ErrorMessage name="confirmPassword" component="p" className="text-yellow-500 text-sm mt-1" />
                          </div>
                        </div>

                        {/* Password Validation Rules */}
                        <div className="grid grid-cols-1 md:grid-cols-2 mt-4">
                          <div className={`flex items-center ${isPasswordValid.minLength ? 'text-green-500' : 'text-gray-400'}`}>
                            <span className="mr-2">✔</span>
                            <span>At least 8 characters</span>
                          </div>
                          <div className={`flex items-center ${isPasswordValid.hasCapitalLetter ? 'text-green-500' : 'text-gray-400'}`}>
                            <span className="mr-2">✔</span>
                            <span>At least one capital letter</span>
                          </div>
                          <div className={`flex items-center ${isPasswordValid.hasNumber ? 'text-green-500' : 'text-gray-400'}`}>
                            <span className="mr-2">✔</span>
                            <span>At least one number</span>
                          </div>
                          <div className={`flex items-center ${isPasswordValid.hasSymbol ? 'text-green-500' : 'text-gray-400'}`}>
                            <span className="mr-2">✔</span>
                            <span>At least one symbol</span>
                          </div>
                          <div className={`flex items-center ${isPasswordValid.noSpaces ? 'text-green-500' : 'text-gray-400'}`}>
                            <span className="mr-2">✔</span>
                            <span>No spaces</span>
                          </div>

                        </div>

                        <div className="flex justify-end pt-4 border-t border-slate-700 mt-5">
                          <button
                            type="submit"
                            disabled={isSubmitting || passwordChangeLoading}
                            className="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-8 rounded-lg disabled:opacity-50"
                          >
                            {passwordChangeLoading ? 'Updating...' : 'Update Password'}
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </>
        )}

        {selectedTab === 'billing' && (
          <div className="flex-1  lg:ml-8">
            billing Section
          </div>
        )}


      </div>
      <UpgradePlanModal
        isUpgradePlanModalOpen={isUpgradePlanModalOpen}
        setIsUpgradePlanModalOpen={setIsUpgradePlanModalOpen}
        subscriptionValidity={user?.subscriptionValidity}
      />
    </div>
  )
}

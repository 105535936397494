/* eslint-disable react-hooks/exhaustive-deps */
// UserProvider.jsx
import React, { useState, useEffect, createContext } from "react";
import useFetch from "../api/HttpRequest";
import Cookies from 'js-cookie';
import { useLocation, useNavigate } from "react-router-dom";
import routeConstant from "../utils/routeConstant";

export const UserContext = createContext({ user: null });

export const UserProvider = (props) => {
  const [user, setUser] = useState(null);
  const [isInitialCheck, setIsInitialCheck] = useState(true);
  const accessToken = Cookies.get('accessToken');
  const {data, error, responseCode, fetchData} = useFetch('/user/check', {
    method: 'GET',
    accessToken,
    silent: true,
  });
  const location = useLocation();
  const navigate = useNavigate();
 
  useEffect(() => {
    const publicRoutes = [
      routeConstant.signin,
      routeConstant.signup,
      routeConstant.forgetPassword,
      routeConstant.accessWithTempKey,
      routeConstant.accountActivate,
      routeConstant.googleAuth
    ];

    // Handle Google auth navigation
    const handleGoogleAuth = () => {
      if (location.pathname === routeConstant.googleAuth) {
        window.location.href = 'https://stg-auth.evattai.com/auth/google';
        return true;
      }
      return false;
    };

    // Check if it's a public route
    const isPublicRoute = publicRoutes.includes(location.pathname) ||
      location.pathname.startsWith('/change-password/');

    // Handle OTP access URL
    const isOtpAccess = location.pathname.startsWith('/access') && location.search.includes('otp=');

    if (!accessToken) {
      if (handleGoogleAuth()) {
        return;
      }
      if (!isPublicRoute && !isOtpAccess) {
        navigate('/signin');
      }
    } else if (isInitialCheck) {
      fetchData();
      setIsInitialCheck(false);
    }
  }, [location.pathname, location.search, accessToken, isInitialCheck]);

  useEffect(() => {
    if (data) {
      if (responseCode === "401") {
        Cookies.remove('accessToken');
        setUser(null);
        const publicRoutes = [
          routeConstant.signin,
          routeConstant.signup,
        ];
        if (!publicRoutes.includes(location.pathname)) {
          navigate('/signin');
        }
      } else if (data.status) {
        setUser(data.data);
      }
    } else if (error && !isInitialCheck) {
      setUser(null);
      const publicRoutes = [
        routeConstant.signin,
        routeConstant.signup,
      ];
      if (!publicRoutes.includes(location.pathname)) {
        navigate('/signin');
      }
    }
  }, [data, error, responseCode, location, navigate, isInitialCheck]);

  return (
    <UserContext.Provider value={user}>{props.children}</UserContext.Provider>
  );
};
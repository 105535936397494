import { Box, TextField } from "@mui/material";
import React from "react";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import CloseIcon from "@mui/icons-material/Close";
import { MoveRight } from 'lucide-react';

const ChatInput = ({
 userQuery,
 setUserQuery,
 onSubmit,
 attachment,
 setAttachment,
 isLoading,
 selectedPdfName,
 setSelectedPdfName,
 setFileSizeWarning,
}) => {
 const handleClose = () => {
   setAttachment(null);
   setUserQuery("");
   setSelectedPdfName("");
 };

 const handleChange = (event) => {
   const file = event.target.files[0];
   if (file.size > 5 * 1024 * 1024) {
     setAttachment(null);
     setSelectedPdfName("");
     setFileSizeWarning(false);
     setTimeout(() => setFileSizeWarning(true), 0);
     return;
   }
   setAttachment(file);
   setSelectedPdfName(file.name);
   event.target.value = "";
 };

 return (
   <Box className="flex flex-col text-white border-1 border-slate-700 -mt-28 md:mt-auto ">
     {selectedPdfName && (
       <Box className="flex items-center justify-between px-4 py-2 bg-slate-700 rounded-t-3xl">
         <Box className="flex items-center gap-2">
           <PictureAsPdfIcon fontSize="medium" className="text-white" />
           <span className="!text-sm">{selectedPdfName}</span>
         </Box>
         <CloseIcon
           fontSize="small"
           className="text-white cursor-pointer"
           onClick={handleClose}
         />
       </Box>
     )}
   <TextField
  variant="standard"
  margin="normal"
  onChange={(e) => setUserQuery(e.target.value)}
  onKeyDown={(e) => {
    if (e.key === "Enter" && !e.shiftKey && !isLoading) {
      e.preventDefault();
      onSubmit(userQuery);
    }
  }}
  value={userQuery}
  fullWidth
  multiline
  maxRows={4}
  disabled={isLoading}
  placeholder="🔮 Ask for help with Legal Research, Contract Drafting and Document Review"
  className={`!bg-[#1C2436FC] !text-[10px] md:!text-[14px] !border-[#414B61] relative border-css !border-[0.8px] !h-[90px] !text-white md:!h-[117px] !rounded-lg  [&_.MuiInputBase-input]:custom-scrollbar ${isLoading ? 'opacity-80' : ''}`}
 
       sx={{
         '& .MuiInputBase-input::-webkit-scrollbar': {
           width: '8px',
         },
         '& .MuiInputBase-input::-webkit-scrollbar-track': {
           background: '#1e293b',
         },
         '& .MuiInputBase-input::-webkit-scrollbar-thumb': {
           background: '#475569',
           borderRadius: '4px',
         },
         '& .MuiInputBase-input::-webkit-scrollbar-thumb:hover': {
           background: '#64748b',
         },
         '& .MuiInputBase-input::placeholder': {
           opacity: '1 !important',
         },
         '& .MuiInputBase-input': {
          fontSize: '10px', 
          '@media (min-width: 768px)': {
            fontSize: '14px'
          }
        },
       }}
       slotProps={{
         input: {
           startAdornment: (
             <label className="self-start px-4 py-2 cursor-pointer">
               <AttachFileIcon fontSize="medium" className="text-white" />
               <input
                 type="file"
                 accept=".pdf, .doc, .docx"
                 hidden
                 onChange={handleChange}
               />
             </label>
           ),
           endAdornment: (
             <Box
               className={ ` !absolute py-2 px-4 pt-[0px] !top-[60px] md:!top-[60px] !right-1 cursor-pointer !self-end ${(!userQuery || isLoading) && "opacity-40 pointer-events-none"
                 }`}
               onClick={() => {
                 if (userQuery && !isLoading) {
                   onSubmit(userQuery, attachment);
                   handleClose();
                 }
               }}
             >
               <div className="flex gap-2 text-[10px]  md:text-sm  my-auto items-center "><span className="">0/2000</span> <div className="border-[1px] flex justify-center items-center w-[16px] h-[16px] md:w-[36px] md:h-[36px] rounded-[2px] md:rounded-lg bg-[#111827]"> <MoveRight  className="!text-white" /></div> </div>
             </Box>
           ),
           disableUnderline: true,
           className: "!py-3 !text-white !font-bold",
         },
       }}
     />
   </Box>
 );
};

export default ChatInput;
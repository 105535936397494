const routeConstant = {
  signin: "/signin",
  forgetPassword: "/forget-password",
  accessWithTempKey: "/access",
  signup: "/signup",
  dashboard: "/",
  accountActivate: "/account/activate/:id/:code",
  bookingSuccess: "/booking/billing/success/:code/:bookingId",
  trialSuccess: "/booking/billing/trial/success/:code/:bookingId",
  changePassword: "/change-password/:id/:tempKey",
  profile: "/profile",
  googleAuth: "https://stg-auth.evattai.com/auth/google",
};

export default routeConstant;
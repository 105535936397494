import { Route, Routes } from "react-router-dom";
import routeConstant from "./utils/routeConstant";
import Dashboard from "./pages/Dashboard";
import SignIn from "./pages/SignIn";
import ForgetPassword from "./pages/ForgetPassword";
import SignUp from "./pages/SignUp";
import { UserProvider } from "./providers/userProvider";
import { Toaster } from "react-hot-toast";
import AccountActivate from "./pages/AccountActivate";
import BookingSuccess from "./pages/BookingSuccess";
import TrialSuccessSuccess from "./pages/TrialSuccess";
import ResetPassword from "./pages/ResetPassword";
import AccessWithTempKey from "./pages/AccessWithTempKey";
import "./App.css";
import ProfileSettings from "./pages/Profile";

const App = () => {
  return (
    <>
      <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16656853263"></script>
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'AW-16656853263');
        `}
      </script>
      <UserProvider>
        <Toaster position="top-center" reverseOrder={false} />
        <Routes>
          <Route path={routeConstant?.signin} element={<SignIn />} />
          <Route path={routeConstant?.forgetPassword} element={<ForgetPassword />} />
          <Route path={routeConstant?.signup} element={<SignUp />} />
          <Route path={routeConstant?.dashboard} element={<Dashboard />} />
          <Route path={routeConstant?.accountActivate} element={<AccountActivate />} />
          <Route path={routeConstant?.bookingSuccess} element={<BookingSuccess />} />
          <Route path={routeConstant?.trialSuccess} element={<TrialSuccessSuccess />} />
          <Route path={routeConstant?.changePassword} element={<ResetPassword />} />
          <Route path={routeConstant?.accessWithTempKey} element={<AccessWithTempKey />} />
          <Route path={routeConstant?.profile} element={<ProfileSettings />} />
        </Routes>
      </UserProvider>
    </>
  );
};

export default App;

/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import useFetch from "../api/HttpRequest";
import {
  Box,
  CircularProgress
} from '@mui/material';
import Cookies from 'js-cookie';
import toast from 'react-hot-toast';

const AccessWithTempKey = () => {
  const accessToken = Cookies.get('accessToken');
  const searchParams = new URLSearchParams(window.location.search);
  const userId = searchParams.get('id');
  const tempKey = searchParams.get('otp');

  const { data: accessData, error: accessError, fetchData: accessFetch } = useFetch('/user/login/with/temp/key', {
    method: 'POST',
    silent: false,
    accessToken,
    successMessage: 'Password reset',
  });

  const fetchCalled = useRef(false);

  useEffect(() => {
    if (tempKey && !fetchCalled.current) {
      accessFetch({
        data: {
          id: Number(userId),
          tempKey: tempKey,
        },
      });
      fetchCalled.current = true;
    }
  }, [tempKey]);

  useEffect(() => {
    if(accessData) {
      if (accessData.status) {
        console.log()
        Cookies.set('accessToken', accessData.data.accessToken);
        window.location.href = "/";
      } else {
        console.log('error', accessData.message);
        window.location.href = "/signin";
      }
    }
  }, [accessData])

  return (
    <Box className="flex items-center justify-center h-screen bg-gray-900">
      <CircularProgress />
    </Box>
  );
};

export default AccessWithTempKey;

/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Typography, useMediaQuery, IconButton, Drawer, Dialog, DialogTitle, DialogContent, DialogActions, Avatar, } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import React, { useContext, useEffect, useRef, useState } from "react";
import routeConstant from "../utils/routeConstant";
import ChatInput from "../components/ChatInput";
import ChatModelDropdown from "../components/ChatModelDropdown";
import CustomLink from "../components/CustomLink";
import DrawerButtons from "../components/DrawerButtons";
import DrawerChatsList from "../components/DrawerChatsList";
import CountrySelector from "../components/CountrySelector";
import { UserContext } from "../providers/userProvider";
import { useNavigate } from "react-router-dom";
import Logo from "../assets/image.png";
import UpgradePlanModal from "../components/UpgradePlanModal";
// import { sendChatQuery, uploadPDF } from "../api/chat";
import Typewriter from "typewriter-effect";
// import { modalArr } from "../utils/constants";
import FormData from "form-data";
import chatIcon from "../assets/chat.png";
import chatIcon2 from "../assets/logo2.png";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import Cookies from "js-cookie"; // Import js-cookie
import useFetch from "../api/HttpRequest";
import toast from "react-hot-toast";

import WarningIcon from '@mui/icons-material/Warning';
import AccountMenu from "../components/AccountMenu";
import ReactMarkdown from 'react-markdown';
// import CircularProgress from '@mui/material/CircularProgress';
import LoadingScreen from "../components/LoadingScreen";
import { Plus, ChevronsLeft } from 'lucide-react';
import { Bell, HelpCircle, Moon, History } from 'lucide-react'


const Dashboard = () => {
  const user = useContext(UserContext);
  const navigate = useNavigate();

  const { subscriptionValidity } = user || {};

  // State Management
  const [model, setModel] = useState(0);
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const [userQuery, setUserQuery] = useState("");
  const [attachment, setAttachment] = useState();
  const [redirect, setRedirect] = useState(null);
  const [isUpgradePlanModalOpen, setIsUpgradePlanModalOpen] = useState(false);
  const lastRef = useRef(null);
  const lastInteractionIndexRef = useRef(null);
  const [isEnterprise, setIsEnterprise] = useState(false);
  const [isTrail, setIsTrail] = useState(false);
  const [finalQueryCount, setFinalQueryCount] = useState(null);
  const [finalDaysRemaining, setFinalDaysRemaining] = useState(null);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isCollapsed, setIsCollapsed] = useState(false)
  const [countyId,setCountyId]=useState(null);



  const [interactions, setInteractions] = useState([]);
  const [selectedChatId, setSelectedChatId] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [fileSizeWarning, setFileSizeWarning] = useState(false);
  const [accessToken, setAccessToken] = useState(Cookies.get('accessToken'));

  const [isDrawerChatsLoading, setIsDrawerChatsLoading] = useState(true);
  const [isChatLoading, setIsChatLoading] = useState(false);

  const bottomRef = useRef(null);

  const [deleteConfirmation, setDeleteConfirmation] = useState({
    open: false,
    chatId: null
  });

  const [selectedPdfName, setSelectedPdfName] = useState("");

  const { data: questionData, fetchData: questionFetch } = useFetch('/chat/ask/question', {
    method: 'POST',
    accessToken,
    silent: true,
  });

  const { data: questionWithPDFData, fetchData: questionWithPDFFetch } = useFetch('/file/upload', {
    method: 'POST',
    accessToken,
    silent: true,
    contentType: 'multipart/form-data',
  });

  const { data: drawerChatsData, fetchData: fetchDrawerChats } = useFetch('/chat/list/by/user', {
    method: 'GET',
    accessToken,
    silent: true,
  });



  const { data: chatQuestionsData, fetchData: fetchChatQuestions } = useFetch('/quesion/list/by/chat', {
    method: 'POST',
    accessToken,
    silent: true,
  });

  const { fetchData: deleteChat } = useFetch('/deleteData', {
    method: 'POST',
    accessToken,
    silent: true,
  });

  const { data: manageSubscriptionResponse, loading: manageSubscriptionLoading, fetchData: fetchManageSubscriptionAdd } = useFetch('/subscription/manage/subscription', {
    method: 'GET',
    accessToken,
    silent: true,
  });

  const { data: trailSubscriptionResponse, loading: trailSubscriptionLoading, fetchData: fetchTrailSubscriptionAdd } = useFetch('/subscription/trail/add', {
    method: 'GET',
    accessToken,
    silent: true,
  });

  useEffect(() => {
    if (user) {
      setFinalQueryCount(user.queryCount);
      if (user.daysRemaining) {
        setFinalDaysRemaining(user.daysRemaining);
      }
    }
  }, [user]);

  useEffect(() => {
    if (finalQueryCount >= 15 && !user?.subscriptionValidity.enterprise && !user.subscriptionValidity.trail) {
      setIsUpgradePlanModalOpen(true);
    }
  }, [finalQueryCount]);

  useEffect(() => {
    if (subscriptionValidity) {
      setIsEnterprise(subscriptionValidity.enterprise);
      setIsTrail(subscriptionValidity.trail);
    }
  }, [subscriptionValidity]);

  useEffect(() => {

    if (chatQuestionsData) {
      const newInteractions = chatQuestionsData.data.flatMap((item) => ([
        { role: "system", content: item.quesion, fromHistory: true },
        { role: "assistant", content: item.answer, fromHistory: true }
      ]));

      setInteractions(newInteractions);

      // Add scroll to bottom after chat history loads
      setTimeout(() => {
        lastRef?.current?.scrollIntoView({
          behavior: "smooth",
          block: "end",
        });
      }, 100);
    }
  }, [chatQuestionsData]);

  let pdfBlob = new Blob([attachment], { type: "application/pdf" });
  const pdfFile = new File([pdfBlob], "file.pdf", { type: "application/pdf" });
  const formData = new FormData();
  formData.append("files", pdfFile, { type: "application/pdf" });

  useEffect(() => {
    if (redirect) {
      navigate(redirect);
    }
  }, [redirect, navigate]);

  useEffect(() => {
    if (accessToken) {
      setIsDrawerChatsLoading(true);
      fetchDrawerChats()
        .finally(() => {
          setIsDrawerChatsLoading(false);
        });
    } else {
      setIsDrawerChatsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  useEffect(() => {
    if (!accessToken) {
      navigate(routeConstant.signin);
    }
  }, [accessToken, navigate]);

  // const scrollToBottom = () => {
  //   requestAnimationFrame(() => {
  //     lastRef?.current?.scrollIntoView({
  //       behavior: "instant",
  //       block: "end",
  //     });
  //   });
  // };

  const onSubmit = async (query, file) => {
    if (!accessToken) {
      toast.error('Please login to continue');
      navigate(routeConstant.signin);
      return;
    }

    if (!query) return;

    // Clear input and add user message immediately
    setUserQuery("");
    setInteractions(prevInteractions => [
      ...prevInteractions,
      {
        role: "system",
        content: query,
        fileName: file || null,
      },
    ]);

    // Scroll to bottom after adding user message
    setTimeout(scrollToBottom, 100);

    try {
      setIsLoading(true);

      if (attachment) {
        const formData = new FormData();
        formData.append("fileId", 1);
        formData.append("question", query);
        formData.append("resultId", selectedChatId || 0);
        formData.append("file", attachment);

        setUploading(true);
        await questionWithPDFFetch({
          data: formData,
        });
        setUploading(false);
      } else {
        await questionFetch({
          data: {
            country:countyId,
            question: query,
            chatId: selectedChatId || 0,
          },
        });
      }

      // Refresh drawer chats after response
      fetchDrawerChats();
      setSelectedPdfName("");
    } catch (error) {
      console.error("Error submitting query:", error);
      setInteractions(prevInteractions => [
        ...prevInteractions,
        {
          role: "assistant",
          content: "Sorry, there was an error processing your request. Please try again.",
          isError: true
        },
      ]);
    } finally {
      setIsLoading(false);
      setTimeout(scrollToBottom, 100);
    }
  };

  useEffect(() => {
    if (questionData) {
      setFinalQueryCount(prev => prev + 1);

      if (!questionData.status) {
        const errorMessage = typeof questionData.message === 'string'
          ? questionData.message
          : "Something went wrong. Please try again later.";

        setInteractions(prevInteractions => [
          ...prevInteractions,
          {
            role: "assistant",
            content: errorMessage,
            isError: true
          },
        ]);
        scrollToBottom();
        return;
      }

      if (questionData?.data?.text) {
        setSelectedChatId(questionData.data.chatId);
        setInteractions(prevInteractions => [
          ...prevInteractions,
          {
            role: "assistant",
            content: questionData.data.text,
          },
        ]);

        if (questionData.data.text === "Maximum prompts used. please update to enterprise plan.") {
          setIsUpgradePlanModalOpen(true);
        }
        
        scrollToBottom();
      }
    }
  }, [questionData]);

  const scrollToBottom = () => {
    lastRef?.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
    });
  };

  useEffect(() => {
    if (questionWithPDFData?.status) {
      setFinalQueryCount((prev) => prev + 1);
      if (questionWithPDFData?.data?.text) {
        setSelectedChatId(questionWithPDFData.data.chatId);
        setInteractions((prevInteractions) => [
          ...prevInteractions,
          {
            role: "assistant",
            content: questionWithPDFData.data.text,
          },
        ]);
        fetchDrawerChats();

        scrollToBottom();
        setAttachment(null);
      }
    }
  }, [questionWithPDFData]);

  useEffect(() => {
    // Update the lastInteractionIndexRef after each render
    lastInteractionIndexRef.current = interactions.length - 1;
  }, [interactions]);

  useEffect(() => {
    model !== 0 && setIsUpgradePlanModalOpen(true);
  }, [model]);

  const isMobile = useMediaQuery('(max-width:767px)');
  const [isDrawerTemporaryOpen, setIsDrawerTemporaryOpen] = useState(false);

  const handleDrawerToggle = () => {
    setIsDrawerTemporaryOpen(!isDrawerTemporaryOpen);
  };

  const handleChatItemClick = async (chatId) => {
    setSelectedChatId(chatId);
    setIsChatLoading(true);
    setInteractions([]);

    await fetchChatQuestions({
      data: { chatId },
    });

    fetchDrawerChats();
    setIsChatLoading(false);

    // Add scroll to bottom after chat loads
    setTimeout(() => {
      lastRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }, 100);
  };

  const handleNewChat = () => {
    setInteractions([]);
    setSelectedChatId(0);
  };

  const handleChatDelete = (chatId) => {
    setDeleteConfirmation({
      open: true,
      chatId
    });
  };

  const confirmDelete = async () => {
    const chatId = deleteConfirmation.chatId;
    setDeleteConfirmation({ open: false, chatId: null });

    try {
      await deleteChat({
        data: {
          tableId: 1,
          resultId: chatId
        }
      });

      // Refresh the chat list after deletion
      fetchDrawerChats();

      // If the deleted chat was selected, clear the interactions and reset selectedChatId
      if (chatId === selectedChatId) {
        setInteractions([]);
        setSelectedChatId(0);
      }

      toast.success('Chat deleted successfully');
    } catch (error) {
      toast.error('Failed to delete chat');
    }
  };

  const handlePlanButtonClick = () => {
    if (isEnterprise || isTrail) {
      fetchManageSubscriptionAdd();

    } else {
      setIsUpgradePlanModalOpen(true)
    }
  }

  const shouldAutoScroll = () => {
    const container = lastRef.current?.parentElement;
    if (!container) return false;

    const threshold = 100; // pixels from bottom
    return container.scrollHeight - container.scrollTop - container.clientHeight < threshold;
  };

  useEffect(() => {
    if (manageSubscriptionResponse?.status) {
      window.location.href = manageSubscriptionResponse.data;
    }
  }, [manageSubscriptionResponse]);

  useEffect(() => {
    const value = user !== null;
    setIsDrawerOpen(value);
  }, [user])

  useEffect(() => {
    // Scroll to the bottom whenever messages array changes
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [userQuery]);

  useEffect(() => {
    if (drawerChatsData?.status && user) {
      setIsPageLoading(false);
    }
  }, [drawerChatsData, user]);

  const handleCountrySelect = (countryId) => {
    setCountyId(prevId => {
     
      return countryId;
    });
  };
  
 
  
  // Add this useEffect to track actual state changes
  useEffect(() => {
  }, [countyId]);

  if (isPageLoading || trailSubscriptionLoading || manageSubscriptionLoading) {
    return (
      <div className="relative w-screen h-screen">
        <LoadingScreen />
      </div>
    );
  }

  return (
    <Box className={`flex h-screen overflow-hidden md:h-[100vh] w-full relative background text-white ${isMobile ? 'flex-col' : 'flex-row'}`}>
      <div className={`flex items-center justify-between w-full px-5 py-2 border-b border-gray-800 ${isMobile ? 'flex-row' : 'hidden '}`}>
        <div className="flex-1">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
          >
            <MenuIcon />
          </IconButton>
        </div>
        <div className="flex my-auto item-center">
          {/* <div className=" mt-5"> <ChatModelDropdown model={model} setModel={setModel} /></div> */}
          <div className=" mt-2 md:mt-0 items-center">
          <CountrySelector onSelect={handleCountrySelect} isCollapsed={true} isMobile={isMobile}/> 
          </div>
         



          {accessToken || user ? (
            <>
              <AccountMenu
                user={user}
                setAccessToken={setAccessToken}
                finalQueryCount={finalQueryCount}
                trailSubscriptionResponse={trailSubscriptionResponse}
                fetchTrailSubscriptionAdd={fetchTrailSubscriptionAdd}
              />
            </>
          ) : (
            <Box className="flex-1 flex gap-[8px] items-center justify-end">
              <CustomLink
                link={routeConstant.signin}
                bg="!bg-slate-800"
                border="!border-black"
              >
                Log In
              </CustomLink>
              {!isMobile && (
                <CustomLink
                  link={routeConstant.signup}
                  bg="!bg-transparent"
                  border="!border-slate-800"
                >
                  Sign Up
                </CustomLink>
              )}
            </Box>
          )}
        </div>
      </div>
      {isMobile && (
        <Drawer
          anchor="left"
          open={isDrawerTemporaryOpen}
          onClose={handleDrawerToggle}
        >
          <Box className="px-5 py-4 w-[75vw] min-h-screen h-full overflow-y-scroll bg-[#111827] flex flex-col gap-6">
            <Box className="flex justify-between">
              <DrawerButtons
                setIsDrawerOpen={setIsDrawerOpen}
                isDrawerOpen={isDrawerOpen}
                onNewChat={handleNewChat}
              />
            </Box>
            <Button
              onClick={handleNewChat}
              className="flex justify-center !bg-gray-700 !text-white !text-[14px] !font-extrabold !capitalize py-3 px-3 rounded-lg cursor-pointer hover:!bg-slate-700"
            >
              New Chat
            </Button>
            <Button
              onClick={handlePlanButtonClick}
              className="flex justify-center !bg-gray-700 !text-white !text-[14px] !font-extrabold !capitalize py-3 px-3 rounded-lg cursor-pointer hover:!bg-slate-700"
            >
              {user?.subscriptionValidity.enterprise && "Manage Subscription"} {user?.subscriptionValidity.trail && `Days remaining: ${user?.daysRemaining}`}
              {!user?.subscriptionValidity.enterprise && !user?.subscriptionValidity.trail &&  "Upgrade (14 days Trial)" }
            </Button>
            {isDrawerChatsLoading ? (
              <Box className="flex flex-col gap-2 px-2">
                {[1, 2, 3].map((i) => (
                  <Box
                    key={i}
                    className="h-12 rounded-lg bg-slate-700 animate-pulse"
                  />
                ))}
              </Box>
            ) : (
              <DrawerChatsList
                chatList={drawerChatsData?.data || []}
                onChatItemClick={handleChatItemClick}
                onChatDelete={handleChatDelete}
              />
            )}
          </Box>
        </Drawer>
      )}
      <button
        onClick={() => setIsCollapsed(!isCollapsed)}
        className={`absolute ${isCollapsed ? "left-[54px] " : "md:left-[283px]"} ${isMobile && "hidden"}  top-1/2 flex h-8 w-8 z-30 -translate-y-1/2 items-center justify-center rounded-full border border-white !bg-[#111827]  text-white`}
      >
        <ChevronsLeft className={`
            h-5 w-5 transition-transform z-30
            ${isCollapsed && "rotate-180"}`}
        />
      </button>
      {!isMobile && <div className={`absolute bottom-0 z-50 transform -translate-x-1/2 ${isCollapsed ? "left-8":"left-[150px]"} `}><CountrySelector onSelect={handleCountrySelect} isCollapsed={isCollapsed} /> </div>}


      {!isMobile && (

        <div
          className={` py-4 ${isMobile ? 'w-full' : 'w-[300px]'} h-full relative overflow-y-auto overflow-x-hidden  custom-scrollbar transition-all duration-300 border-[0.8px] border-[#39445B] bg-[#111827] ${isCollapsed ? "w-[70px]" : "w-[300px]"}`}
        >

          <Box className="flex flex-col w-full gap-0">
            {!isCollapsed ? (
              <Button
                onClick={handleNewChat}
                className="!flex !justify-start h-[64px] bg-[#0f1117] !text-white !text-[14px] !font-extrabold !capitalize py-3 px-3 rounded-lg cursor-pointer hover:!bg-slate-700"
              >
                <img src={Logo} alt="Evatt.AI Logo" className="flex justify-start mb-6 w-[110px] auto !ml-5" />
              </Button>
            ) : (
              <Button
                onClick={handleNewChat}
                className="flex justify-center h-[64px] bg-[#0f1117] !text-white !text-[14px] !font-extrabold !capitalize py-3 px-3  cursor-pointer hover:!bg-slate-700"
              >
                <img src={chatIcon2} alt="Logo" className="w-auto h-[35px]" />
              </Button>
            )}
            <button
              onClick={handleNewChat}
              className="flex justify-center bg-[#111827] !text-white h-[64px] !text-[14px] !border-[#39445B] !border-b-[1px] !border-t-[1px] !font-extrabold !capitalize py-5 px-5 w-full cursor-pointer"
            >
              {isCollapsed && <Plus className="h-[35px] w-[35px]" />}
              {!isCollapsed && <span className="flex"> <Plus className="h-4 w-4] mr-2 my-auto" /> New Chat</span>}
            </button>

          </Box>
          { }
          {isDrawerChatsLoading ? (
            <Box className="flex flex-col gap-2 px-2 mx-5 mt-5">
              {[1, 2, 3].map((i) => (
                <Box
                  key={i}
                  className="h-12 rounded-lg bg-slate-700 animate-pulse"
                />
              ))}
            </Box>
          ) : (
            <>
              {isCollapsed ? (<button
                 onClick={() => setIsCollapsed(!isCollapsed)}
                className="flex justify-center bg-[#111827] !text-white h-[64px] !text-[14px] !border-[#39445B] !border-b-[1px] !border-t-[1px] !font-extrabold !capitalize py-5 px-5 w-full cursor-pointer"
              >
                {isCollapsed && <History className="h-[35px] w-[35px]" />}
                {/* {!isCollapsed && <span className="flex"> <History className="h-4 w-4" /> New Chat</span>} */}
              </button>) : (
                <div className="mt-5 mx-2 mb-16">
                  {!isCollapsed && <div className="px-2 py-2 text-sm text-gray-400 uppercase mt-5">Chat history</div>}
                  <DrawerChatsList
                    chatList={drawerChatsData?.data || []}
                    onChatItemClick={handleChatItemClick}
                    onChatDelete={handleChatDelete}
                  />
                </div>
              )}
            </>
          )}

        </div>


      )}
      <Box className="flex items-center flex-1 h-full">
        <Box className="relative flex flex-col justify-between w-full h-full gap-10  pt-3 pb-4 ">
          <Box className={`h-[70px] hidden md:flex justify-between !border-[#39445B] !border-b-[1px] items-center w-full ${isMobile ? 'flex justify-between' : ''}`}>
            <Box className="flex px-5 gap-4 mb-[1px]">
              {/* {!isDrawerOpen && !isMobile && (
                <DrawerButtons
                  setIsDrawerOpen={setIsDrawerOpen}
                  isDrawerOpen={isDrawerOpen}
                  onNewChat={handleNewChat}
                />
              )} */}
              {/* <ChatModelDropdown model={model} setModel={setModel} /> */}
            </Box>
            <div className="flex  gap-4">
              {accessToken || user ? (
                <>
                  {/* Top Navigation */}
                  <div className="flex items-center justify-end gap-4 py-4 ">
                    <button onClick={handlePlanButtonClick} className="px-4 py-2 text-[12px] text-white hover:bg-gray-800 rounded-lg transition-colors">
                    ✨ {user?.subscriptionValidity.enterprise && "Manage Subscription"} {user?.subscriptionValidity.trail && `Days remaining: ${user?.daysRemaining}`}
                     {!user?.subscriptionValidity.enterprise && !user?.subscriptionValidity.trail &&  "Upgrade (14 days Trial)" }
                    </button>
                    {/* <Box className={`flex px-5 gap-4 mb-[1px] `}>

                      <ChatModelDropdown model={model} setModel={setModel} />
                    </Box>
                    <button className="p-2 text-gray-400 hover:bg-gray-800 rounded-lg transition-colors">
                      <HelpCircle className="w-5 h-5" />
                    </button>
                    <button className="p-2 text-gray-400 hover:bg-gray-800 rounded-lg transition-colors">
                      <Bell className="w-5 h-5" />
                    </button>
                    <button className="p-2 text-gray-400 hover:bg-gray-800 rounded-lg transition-colors">
                      <Moon className="w-5 h-5" />
                    </button> */}
                  </div>
                  <hr className="w-[1px] h-[22px] bg-white mt-6 mx-4" />
                  <AccountMenu
                    user={user}
                    setAccessToken={setAccessToken}
                    finalQueryCount={finalQueryCount}
                    trailSubscriptionResponse={trailSubscriptionResponse}
                    fetchTrailSubscriptionAdd={fetchTrailSubscriptionAdd}
                  />
                </>
              ) : (
                <Box className="flex gap-[8px] items-center">
                  <CustomLink
                    link={routeConstant.signin}
                    bg="!bg-slate-800"
                    border="!border-black"
                  >
                    Log In
                  </CustomLink>
                  <CustomLink
                    link={routeConstant.signup}
                    bg="!bg-transparent"
                    border="!border-slate-800"
                  >
                    Sign Up
                  </CustomLink>
                </Box>
              )}
            </div>
          </Box>
          {!(interactions.length > 0) && (
            <Box className="!text-[65px] !font-bold flex justify-center gap-5 !text-white text-center pt-[100px]">
              <img src={Logo} alt="logo" width={isMobile ? 200 : 160} />
            </Box>
          )}
          <Box
            className={`${isMobile ? 'w-full' : 'w-[70%]'} h-[100%] overflow-y-auto custom-scrollbar mx-auto flex flex-col gap-4`}
            sx={{
              overflowY: 'auto',
              maxHeight: 'calc(100vh - 200px)', // Adjust this value based on your header/footer heights
              '&::-webkit-scrollbar': {
                width: '8px',
              },
              '&::-webkit-scrollbar-track': {
                background: '#1e293b',
              },
              '&::-webkit-scrollbar-thumb': {
                background: '#475569',
                borderRadius: '4px',
              },
            }}
          >
            {isChatLoading ? (
              <Box className="flex flex-col gap-4 px-2">
                <Box>
                  <Box className="flex justify-end w-full mb-4">
                    <Box className="h-12 bg-slate-700 rounded-3xl animate-pulse max-w-lg w-[300px]" />
                  </Box>
                  <Box className="flex items-start gap-5">
                    <Box className="flex justify-start w-full">
                      <Box className="h-12 bg-slate-700 rounded-3xl animate-pulse max-w-lg w-[300px]" />
                    </Box>
                  </Box>
                </Box>
              </Box>
            ) : interactions.length > 0 ? (
              interactions.map((item, index) => {
                const isLastInteraction = index === interactions.length - 1;

                if (item?.role === "system") {
                  // console.log("item", item);

                  return (
                    <Box key={index} className="flex flex-col gap-2 text-white">
                      {item.fileName && (
                        <Box className="flex justify-end w-full">
                          <Box className="flex items-center gap-2 !py-3  border border-slate-700 !rounded-xl !px-5 !text-[14px] !text-white max-w-lg">
                            <Box className="!p-[5px] !rounded-lg ">
                              <PictureAsPdfIcon
                                fontSize="medium"
                                className="text-white "
                              />
                            </Box>
                            {item.fileName.name || item.fileName}
                          </Box>
                        </Box>
                      )}
                      <Box className="flex justify-start item-center w-full gap-2">
                        <IconButton className="!w-[32px] !h-[32px] !rounded-full overflow-hidden">
                          <Avatar className="!w-[32px] !h-[32px] rounded-full" src="/path/to/profile/image.jpg" />
                        </IconButton>
                        <Typography className="!py-1  my-auto !rounded-3xl ml-2 !text-[14px] !text-white max-w-5xl">
                          {item?.content}
                        </Typography>
                      </Box>
                    </Box>
                  );
                } else {
                  return (
                    <Box key={index} className="grid items-start gap-2">
                      <div className="flex gap-1">
                      <div className="w-[28px] h-[28px] p-[-1px] rounded-3xl bg-white  mt-1">
                  <img src={chatIcon} alt="evatt-logo" className="w-auto z-20 h-auto" />
                </div>
                        <p className="!text-[14px] item-center mt-2 ml-2">Evatt.Ai</p>

                      </div>
                      <Box className="flex justify-start w-full">
                        <Typography
                          ref={lastRef}
                          className="!py-2  !rounded-3xl !px-5 !text-[14px] !text-white max-w-5xl break-words"
                        >
                          {isLastInteraction && index > lastInteractionIndexRef.current && !item.fromHistory ? (
                            <Typewriter
                              options={{
                                delay: 0,
                                cursor: "|",
                                autoStart: true,
                              }}
                              onInit={(typewriter) => {
                                const content = String(item?.content || '');
                                const words = content.split(" ");

                                let typeChain = typewriter;
                                if (content) {
                                  words.forEach((word, index) => {
                                    typeChain = typeChain
                                      .typeString(word + " ")
                                      .pauseFor(50)
                                      .callFunction(() => {
                                        if (shouldAutoScroll()) {
                                          lastRef?.current?.scrollIntoView({
                                            behavior: "smooth",
                                            block: "end",
                                          });
                                        }
                                      });
                                  });
                                } else {
                                  typeChain.typeString("Something went wrong. Please try again later.")
                                    .pauseFor(50);
                                }
                              }}
                            />
                          ) : (
                            <ReactMarkdown
                              components={{
                                h1: ({ node, ...props }) => <h1 className="my-4 text-2xl font-bold" {...props} />,
                                h2: ({ node, ...props }) => <h2 className="my-3 text-xl font-bold" {...props} />,
                                h3: ({ node, ...props }) => <h3 className="my-2 text-lg font-bold" {...props} />,
                                p: ({ node, ...props }) => <p className="my-2" {...props} />,
                                ul: ({ node, ...props }) => <ul className="my-2 ml-4 list-disc" {...props} />,
                                ol: ({ node, ...props }) => <ol className="my-2 ml-4 list-decimal" {...props} />,
                                li: ({ node, ...props }) => {
                                  if (!props.children || (Array.isArray(props.children) && props.children.length === 0) || (typeof props.children === 'string' && props.children.trim() === '')) {
                                    return null;
                                  }
                                  return <li {...props} />;
                                },
                              }}
                            >
                              {String(item?.content || 'Something went wrong. Please try again later.')}
                            </ReactMarkdown>
                          )}
                        </Typography>
                      </Box>
                    </Box>
                  );
                }
              })
            ) : (
              <div>

                <div>
                  <Box className="flex items-center justify-center xl:px-5">
                    <Typography className="!text-white text-center  md:!text-[12px] xl:!text-[28px] lg:!text-[20px] !font-bold">
                      <Typewriter
                        options={{
                          delay: 0,
                          cursor: "|",
                          autoStart: true,
                        }}
                        onInit={(typewriter) => {
                          const words = "Making Lawyers Lives Easier".split(" ");
                          let typeChain = typewriter;
                          words.forEach((word, index) => {
                            typeChain = typeChain
                              .typeString(word + " ")
                              .pauseFor(100);
                          });

                          typeChain
                            .callFunction(() => {
                              const typewriterElement = document.querySelector(
                                ".Typewriter__cursor"
                              );
                              if (typewriterElement) {
                                typewriterElement.style.display = "none";
                              }
                            })
                            .start();
                        }}
                      />
                    </Typography>
                  </Box>
                  <Box className="flex items-center justify-center xl:px-5">
                    <Typography
                      className="xl:!text-[28px] md:!text-[12px] text-center !font-bold lg:!text-[20px] "
                      // background: linear-gradient(90deg, #83FFFF 0%, #00A068 100%);
                      // background: linear-gradient(90deg, #83FFFF 0%, #00A068 100%);


                      style={{
                        background: "linear-gradient(90deg, #83FFFF 0%, #00A068 100%) ",
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                        backgroundClip: "text",
                        color: "transparent",
                      }}
                    >
                      <Typewriter
                        options={{
                          delay: 0,
                          cursor: "|",
                          autoStart: true,
                        }}
                        onInit={(typewriter) => {
                          const words = "AI for Lawyers".split(" ");
                          let typeChain = typewriter;
                          words.forEach((word) => {
                            typeChain = typeChain.typeString(word + " ").pauseFor(100);
                          });

                          typeChain
                            .callFunction(() => {
                              const typewriterElement = document.querySelector(
                                ".Typewriter__cursor"
                              );
                              if (typewriterElement) {
                                typewriterElement.style.display = "none";
                              }
                            })
                            .start();
                        }}
                      />
                    </Typography>
                  </Box>
                  <Box className="flex  items-center justify-center lg:mt-5 xl:mt-5 px-5">
                    <Typography className="!text-white lg:!text-[14px] md:!text-[8px] text-center !font-bold">
                      <Typewriter
                        options={{
                          delay: 0,
                          cursor: "|",
                          autoStart: true,
                        }}
                        onInit={(typewriter) => {
                          const words = "Save 2hrs per day through AI that automates legal research, contract drafting and document review".split(" ");
                          let typeChain = typewriter;
                          words.forEach((word, index) => {
                            typeChain = typeChain
                              .typeString(word + " ")
                              .pauseFor(100);
                          });

                          typeChain
                            .callFunction(() => {
                              const typewriterElement = document.querySelector(
                                ".Typewriter__cursor"
                              );
                              if (typewriterElement) {
                                typewriterElement.style.display = "none";
                              }
                            })
                            .start();
                        }}
                      />
                    </Typography>
                  </Box>
                </div>
              </div>
            )}
            {uploading && (
              <Box className="flex items-start gap-5">
                <div className="w-[28px] h-[28px] p-[0.2px] rounded-3xl bg-white  mt-1">
                  <img src={chatIcon} alt="evatt-logo" className="w-auto z-20 h-auto" />
                </div>
                <Typography className="!py-3.5 !bg-slate-700 !rounded-3xl !px-5 !text-[14px] !text-white">
                  Uploading...
                </Typography>
              </Box>
            )}
            {isLoading && (
              <Box className="flex items-start gap-5">
                <div className="w-[28px] h-[28px] p-[0.2px]  rounded-3xl bg-white   mt-1">
                  <img src={chatIcon} alt="evatt-logo" className="w-auto z-20  h-auto" />
                </div>
                <Typography className="!py-3.5 !bg-slate-700 !rounded-3xl !px-5 !text-[14px] !text-white">
                  <div className="flex flex-row gap-1">
                    <div className="w-2 h-2 bg-white rounded-full animate-bounce"></div>
                    <div className="w-2 h-2 rounded-full bg-white animate-bounce [animation-delay:-.3s]"></div>
                    <div className="w-2 h-2 rounded-full bg-white animate-bounce [animation-delay:-.5s]"></div>
                  </div>
                </Typography>
              </Box>
            )}
            <div ref={bottomRef} />
          </Box>

          <Box
            className={`mx-auto ${interactions.length > 0 ? "bottom-3" : "bottom-[50%]"} ${isMobile ? 'w-[calc(100vw-40px)]' : 'w-[70%]'}`}
          >
            {user?.subscriptionValidity.free &&
              finalQueryCount < 10 ? (
              <Typography className="py-[3px] !text-[13px] text-end  rounded-md">
                {15 - (finalQueryCount || 0)} Queries Remaining
              </Typography>
            ) : finalQueryCount >= 10 && (
              <Typography className="py-[3px] !text-[13px] text-end bg-yellow-600 rounded-md">
                {finalQueryCount >= 15 ? 'Maximum prompts used, please update your credit card details to continue your Free 14 Day Trial or upgrade to the Enterprise Plan' : finalQueryCount === 14 ? 'Final query remaining' : finalQueryCount >= 12 ? 'Almost out of queries' : finalQueryCount >= 10 ? 'Running low on queries' : finalQueryCount} &#40;{15 - (finalQueryCount || 0)} Queries Remaining&#41;
              </Typography>
            )}
            {(user?.subscriptionValidity.trail && finalDaysRemaining <= 10) && (
              <Typography className="py-[3px] !text-[13px] text-center bg-yellow-600 rounded-md">
                {(finalDaysRemaining >= 1 && finalDaysRemaining <= 10) ? `Trial ends in ${finalDaysRemaining} days` : finalDaysRemaining === 0 && 'Trial ended'}
              </Typography>
            )}
            {/* <div className={`flex items-center justify-start gap-5 mb-4 ${interactions.length > 0 ? 'hidden' : ''}  ${isMobile ? 'hidden' : ''}`}>
              <button className="w-[142px] h-[32px] lg:h-[68px] rounded-lg border-[1px] border-[#414B61] flex items-center justify-center hover:!bg-[#374257]  bg-[#1B2436] md:text-[8px] text-[12px] text-white">Legal Research</button>
              <button className="w-[142px]  h-[32px] lg:h-[68px] rounded-lg border-[1px] border-[#414B61] flex items-center justify-center hover:!bg-[#374257] bg-[#1B2436] md:text-[8px]  text-[12px] text-white">Contract Drafting</button>
              <button className="w-[142px]  h-[32px] lg:h-[68px] rounded-lg border-[1px] border-[#414B61] flex items-center justify-center hover:!bg-[#374257] bg-[#1B2436] md:text-[8px]  text-[12px] text-white">Document Review</button>
              <button className="w-[142px]  h-[32px] lg:h-[68px] rounded-lg border-[1px] border-[#414B61] flex items-center justify-center hover:!bg-[#374257] bg-[#1B2436] md:text-[8px]  text-[12px] text-white">General Query</button>
            </div> */}
            <ChatInput
              userQuery={userQuery}
              setUserQuery={setUserQuery}
              onSubmit={onSubmit}
              attachment={attachment}
              setAttachment={setAttachment}
              isLoading={isLoading}
              selectedPdfName={selectedPdfName}
              setSelectedPdfName={setSelectedPdfName}
              setFileSizeWarning={setFileSizeWarning}
            />
          </Box>
        </Box>
      </Box>
      <UpgradePlanModal
        isUpgradePlanModalOpen={isUpgradePlanModalOpen}
        setIsUpgradePlanModalOpen={setIsUpgradePlanModalOpen}
        subscriptionValidity={user?.subscriptionValidity}
      />
      <Dialog
        open={deleteConfirmation.open}
        onClose={() => setDeleteConfirmation({ open: false, chatId: null })}
        PaperProps={{
          style: {
            borderRadius: '12px',
            backgroundColor: '#1e293b', // slate-800
            color: 'white',
            minWidth: '300px'
          }
        }}
      >
        <DialogTitle className="text-center">Delete Confirmation</DialogTitle>
        <DialogContent>
          <p className="text-center">Are you sure you want to delete this chat?</p>
        </DialogContent>
        <DialogActions className="justify-center pb-4">
          <Button
            onClick={() => setDeleteConfirmation({ open: false, chatId: null })}
            className="!px-6 !py-2 !text-gray-300 !border !border-gray-600 hover:!bg-gray-700"
          >
            Cancel
          </Button>
          <Button
            onClick={confirmDelete}
            className="!px-6 !py-2 !bg-red-500 !text-white hover:!bg-red-600"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={fileSizeWarning}
        onClose={() => setFileSizeWarning(false)}
        PaperProps={{
          style: {
            borderRadius: '12px',
            backgroundColor: '#1e293b', // slate-800
            color: 'white',
            minWidth: '300px'
          }
        }}
      >
        <DialogTitle className="text-center">File Size Warning!</DialogTitle>
        <DialogContent>
          <div className="flex flex-col items-center w-full">
            <WarningIcon style={{ fontSize: '50px' }} />
            <p className="w-full mt-3 text-center">File size must be lower than 5MB</p>
          </div>
        </DialogContent>
        <DialogActions className="justify-center pb-4">
          <Button
            onClick={() => setFileSizeWarning(false)}
            className="!px-6 !py-2 !text-gray-300 !border !border-gray-600 hover:!bg-gray-700"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Dashboard;
